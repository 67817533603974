import React from "react";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet"
import Layout from "../components/layout";

import { TitleHeader } from "../components/defaults/styles/elements"
import Ticket from "../components/defaults/assets/svg/ticket.svg"
import { wrapper, Banner } from "../components/defaults/styles/elements";
import mq from "../components/defaults/styles/mediaquerys";
import PressFooter from "../components/pressfooter"
import { color } from "../components/defaults/styles/theme";
import Zap from '../components/defaults/assets/svg/zap.svg';
import { People } from '../components/defaults/assets/svg/mobilenav';

const seo = {
  frontmatter: {
    title: "Register",
  },
}


const Attendify = () => {

  return (
    <>
      <Helmet>
        <script src="https://attendify.co/widget/client.js"></script>
        {/* <script src="https://s3.amazonaws.com/attendify-pixel-analytics-core/attendify-pixel-widget.js"></script> */}
      </Helmet>
      <iframe
        src="https://attendify.co/widget/expo-8-BN9SRCB?label=Register Now&color=0cb78b&text_color=FFFFFF"
        frameBorder="0"
        sandbox="allow-scripts allow-top-navigation allow-modals allow-same-origin allow-forms"
        id="er--widget"
        title="Attendify_Widget"
        css={css`
          max-width: 50rem;
          width: calc(100% + 16px);
          margin: -8px;
        `}
      ></iframe>
    </>
  )
}

const item = css`
  flex: 0 0 calc(50% - 1rem);
  margin-bottom: 2em;
`

const TicketTypes = ({name, content, icon, children}) => {
  
  const TypeContainer = ({className, name, content, icon, children}) => {
    return (
      <li className={className}
        css={css`
          background: ${color.accent_light};
          border-radius: 5px;
          font-size: 0.9rem;
          padding-bottom: 2em;
          margin-bottom: 0;
          p {
            margin-bottom: 0;
            line-height: 1.9em;
          }
          ul {
            margin: 1.5em 0 0;
            list-style: none;
            li {
              font-size: 0.85rem;
              position: relative;
              padding: 1em 2rem;
              margin-bottom: 0;
              &:last-of-type {
                &:before {
                  content: none;
                }
              }
              &:before {
                content: "";
                background: ${color.main_dark};
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
                opacity: 0.2;
                position: absolute;
              }
            }
          }
        `}
      >
        <TitleHeader
          title={name}
          icon={icon}
          css={css`
            padding: 0;
            padding: 2rem;
            h2 {
              font-size: 1.2em;
            }
          `}
        />
        {children}
      </li>
    )
  }
  
  return (
    <ul
      css={css`
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        flex-direction: column;
        ${mq[2]} {
          flex-direction: row;
        }
      `}
    >
      <TypeContainer name="General Admission" icon={<People />} css={item}>
        <div css={css``}>
          <p
            css={css`
              padding: 0 2rem;
            `}
          >
            This ticket gives you free access to the 2-day virtual EXPO 8.
          </p>
          <p
            css={css`
              padding: 0 2rem;
            `}
          >
            This includes:{" "}
          </p>
          <ul>
            <li>
              Create your own “attendee profile” to be featured on our website.
            </li>
            <li>
              Stream 2 days of thought-provoking content from top thinkers,
              doers and disruptors from across industries
            </li>
            <li>
              Explore more than 30 pilots, PoCs and technologies between
              late-stage startups and corporate partners ranging from: crypto
              economy to voice enhancement, digital transformation to immersive
              workspaces, speech analytics, blockchain & more!
            </li>
            <li>
              See for yourself what innovations are driving global industry
              players today
            </li>
            <li>
              Celebrate innovation with a global community of 5K+ attendees
            </li>
          </ul>
        </div>
      </TypeContainer>

      <div css={item}>
        <TypeContainer name="All Access Pass" icon={<Zap />}>
          <p
            css={css`
              padding: 0 2rem;
            `}
          >
            This ticket is invite-only and grants you access to the 2-day
            virtual EXPO 8 + all the bells and whistles.
          </p>
          <div
            css={css`
              border: 1px solid ${color.main_dark};
              padding: 2rem;
              margin: 2rem;
              border-radius: 5px;
              color: ${color.main_dark};
              display: flex;
              justify-content: center;
              text-align: center;
            `}
          >
            <p>
              This includes everything of the <b>General Admission</b>{" "}
              package.
            </p>
          </div>
          <div>
            {/* <p
              css={css`
                padding: 0 2rem;
              `}
            >
              Additionally those with all-access passes will gain entry to:
            </p> */}
            <ul>
              <li>
                1:1 networking opportunities through a custom event app and
                virtual platform
              </li>
              <li>
                Participate in limited-entry breakout sessions hosted by
                startups, partners, and special guests.
              </li>
              <li>Unlock additional content and materials on our website</li>
            </ul>
          </div>
        </TypeContainer>

        <div
          css={css`
            background: ${color.main_dark};
            padding: 1.5em 2rem;
            border-radius: 5px;
            font-size: 0.85em;
            font-weight: 500;
            margin-top: 1em;
            p,
            a {
              line-height: 1.6em;
              text-decoration: none;
              color: white;
              margin-bottom: 0;
            }
          `}
        >
          <p>
            If you are an employee of a STARTUP AUTOBAHN partner/startup company
            you might qualify for this ticket category. Please contact your
            champion or Hannah (
            <a href="mailto:hannah@pnptc.com">hannah@pnptc.com</a>) for more
            information and an unlock code.
          </p>
        </div>

      </div>
    </ul>
  )
}


const Register = () => {
  return (
    <>
      <Layout seo={seo}>
        <TitleHeader
          title="Get your ticket now"
          icon={<Ticket />}
          subtitle="Register now for two days of unforgettable content. From esports to sustainable energy, vegan car interiors to production processes of the future – your EXPO ticket will grant you access to top thinkers, new technologies, lively panel discussions, and lots more.</p><p>Check out our ticket options below and get your ticket, today."
          css={css`
            svg {
              fill: white;
              stroke: none;
            }
          `}
        />
        <Banner
          type="info"
          content="<p>If you do have any questions about the registration process or would like to have additional information about the event, please reach out to the Program Manager of STARTUP AUTOBAHN powered by Plug and Play, Hannah via – <a href='mailto:hannah@pptc.com'>hannah@pnptc.com</a></p>"
          css={css`
            z-index: 1;
            position: relative;
            a {
              white-space: nowrap;
            }
          `}
        />
        <section
          css={css`
            background: ${color.main_light};
            padding-top: 7rem;
            margin-top: -2.5rem;
          `}
        >
          <div
            css={[
              wrapper,
              css`
                padding-bottom: 8rem;
              `,
            ]}
          >
            <h2>EXPO 8 Ticket Types</h2>
            <TicketTypes />

            <h2
              css={css`
                margin-top: 6rem;
                margin-bottom: 0.75em;
              `}
            >
              Get your ticket
            </h2>
            <Banner
              type="info"
              content="<p>In case you have received an unlock code for the <b>All Access Pass</b>, please redeem it by selecting <i>Have a Code?</i>. With that you unlock the ability to register yourself as an All Access Pass member.</p>"
              css={css`
                margin-left: -1em;
                width: calc(100% + 2em);
                margin-bottom: 3em;
              `}
            />
            <Attendify />
          </div>
        </section>
        <PressFooter theme={color.main_light} />
      </Layout>
    </>
  )
}

export default Register;